const __request = require(`./__request/`);
/**
 * 酒店检索
 */
export default function (val) {
  var pParameter = {}
  pParameter.method = "POST"
  // pParameter.urlSuffix = "/hotel/manager/base/searchHotels"
  // pParameter.urlSuffix = "/hotel/buyerBase/searchHotels"

  pParameter.urlSuffix = "/hotel/common/buildSolr/getHotelListBySolr"
  pParameter.data = val
  return new Promise(function (resolve, reject) {
    resolve(__request(pParameter))
  })
}
