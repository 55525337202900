// 潘磊写的
import { setSession, getSession } from "@/lib/deprecated/utils/caching";

// 请求酒店品牌
import queryHotelBrand from "@/lib/data-service/hotel/queryHotelBrand.js";
// 请求酒店产品
import hotelCommonBuildSolrGetHotelListBySolr from "@/lib/data-service/hotel/hotelCommonBuildSolrGetHotelListBySolr";
// 请求酒店产品价格
import getHotelMinPriceByHotelId from "@/lib/data-service/hotel/getHotelMinPriceByHotelId";

// 国内酒店城市组件
import HotelDomesticCitySelector from "yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/hotel-domestic-city-selector/1.0.0/index.vue";
// 国外酒店城市组件
import HotelForeignCitySelector from "yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/hotel-foreign-city-selector/1.0.0/index.vue";
export default {
  name: "hotelSearch",
  components: {
    HotelDomesticCitySelector,
    HotelForeignCitySelector,
  },
  data() {
    return {
      pickerBeginDateBefore: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7; //
        },
      },
      pickerOption: this.setTime(),
      // 区域类型列表
      cityTypeList: [
        {
          value: "1",
          label: "国内",
        },
        {
          value: "2",
          label: "港澳台",
        },
        {
          value: "3",
          label: "国际",
        },
      ],
      // 港澳台城市数据
      HongkongList: [
        {
          value: "30000898",
          label: "香港",
        },
        {
          value: "70139",
          label: "澳门",
        },
        {
          value: "30001017",
          label: "台湾",
        },
      ],
      // 搜索列表参数
      formData: {
        currentPage: 1, //当前页码
        pageSize: 10, //每页条数
        checkInDate: "", //入住时间
        checkOutDate: "", //退房时间
        countryType: "1", //地区类型 //地区类型 1、国内，2港澳台，3、国际
        keyword: "", //关键字
        cityId: "", //城市id
        cityName: "", //城市名称
        hotelStar: "", //酒店星级
        hotelBrand: "", //酒店品牌
        productType: "3", //产品类型
        salesSort: "", //销量排序  升序：asc,降序：desc
      },
      // 产品列表数据
      list: [],
      pager: {
        total: 0,
        pageSizes: [10, 15, 20, 25, 30],
      },
      loading: false,
      // 国内选中城市
      hotelInlandCity: null,
      // 港澳台
      GangAoTaiId: "",
      // 国外城市
      hotelForeignCity: null,
      // 星级品牌可选项
      allocation: {
        hotelStar: [
          {
            value: "",
            label: "不限",
          },
          {
            value: "2",
            label: "经济型",
          },
          {
            value: "3",
            label: "舒适型",
          },
          {
            value: "4",
            label: "高档型",
          },
          {
            value: "5",
            label: "豪华型",
          },
        ],
        hotelBrand: [],
        // 排序 升序：asc,降序：desc
        sortList: [
          { text: "综合", value: "1" },
          { text: "销量", value: "2", icon: true, type: "desc" },
        ],
      },
      foldBrand: true,
      sortType: "1",
    };
  },
  watch: {
    hotelInlandCity(val) {
      if (val) {
        const form = this.formData;
        form.cityId = val.cityId;
        form.cityName = val.cityCn;
      }
    },
    GangAoTaiId(val) {
      if (val) {
        const form = this.formData;
        form.cityId = val;
        form.cityName = this.HongkongList.filter(
          (item) => item.value === val
        )[0].label;
      }
    },
    hotelForeignCity(val) {
      if (val) {
        const form = this.formData;
        form.cityId = val.cityId;
        form.cityName = val.cityCn;
      }
    },
  },
  filters: {
    format_starText(val) {
      let list = [];
      if (["20", "25"].includes(String(val))) {
        return "经济型";
      } else if (["30", "35"].includes(String(val))) {
        return "舒适型";
      } else if (["40", "45"].includes(String(val))) {
        return "高档型";
      } else if (["50", "55"].includes(String(val))) {
        return "豪华型";
      }
    },
  },
  methods: {
    //日历时间查询
    setTime() {
      let _this = this;
      return {
        disabledDate: (time) => {
          if (_this.formData.checkInTime) {
            if (typeof _this.formData.checkInTime == "object") {
              return (
                time.getTime() - 1000 * 3600 * 24 < _this.formData.checkInTime
              );
            } else {
              return (
                time.getTime() - 1000 * 3600 * 24 <
                this.$moment(_this.formData.checkInTime)._d
              );
            }
          } else {
            return time.getTime() < Date.now();
          }
        },
      };
    },
    change_checkInDate () {
      this.formData.checkOutDate = '';
      this.$refs.outTime.focus();
    },
    req_list() {
      this.loading = true;
      hotelCommonBuildSolrGetHotelListBySolr(this.formData)
        .then((result) => {
          const datas = result.dataMap;
          datas.rows.forEach((item) => (item.cose = ""));
          this.list = datas.rows;
          this.pager.total = datas.total;
          this.req_minPrice();
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    req_minPrice() {
      const list = this.list;
      if (!list.length) return;
      const hotelIds = list.map((item) => item.hotelId);
      const { checkInDate, checkOutDate } = this.formData;
      getHotelMinPriceByHotelId({ hotelIds, checkInDate, checkOutDate })
        .then((result) => {
          const priceList = result.minPriceResults;
          list.forEach((item) => {
            item.cose = priceList.filter((node) => {
              if (node.hotelId == item.hotelId) return node;
            })[0].minPrice;
          });
        })
        .catch((err) => {});
    },

    req_hotelBrand() {
      queryHotelBrand()
        .then((result) => {
          const hotelBrand = result.pageResult.pageData;
          hotelBrand.unshift({ hotelBrandName: "不限", hotelBrandId: "" });
          this.allocation.hotelBrand = result.pageResult.pageData;
        })
        .catch((err) => {});
    },

    init() {
      const route = this.$route;
      if (route.meta.refresh) {
        const form = this.formData;
        const {
          checkInTime: checkInDate,
          checkOutTime: checkOutDate,
          cityId,
          cityName,
          countryType,
          keyword,
          hotelStar,
        } = JSON.parse(route.query.data);
        form.checkInDate = checkInDate;
        form.checkOutDate = checkOutDate;
        form.cityId = cityId;
        form.cityName = cityName;
        form.countryType = countryType;
        form.keyword = keyword ? keyword : "";
        form.hotelStar = hotelStar ? String(hotelStar) : "";
      } else {
        this.formData = getSession("hotelSearchPageForm");
      }
      const form = this.formData;
      const cityId = form.cityId;
      this.$nextTick(() => {
        switch (form.countryType) {
          case "1":
            this.hotelInlandCity = { cityId };
            break;
          case "2":
            this.GangAoTaiId = cityId;
            break;
          case "3":
            this.hotelForeignCity = { cityId };
            break;
        }
      });

      this.req_list();
      this.req_hotelBrand();
    },

    search() {
      this.change_currentPage(1);
    },
    //切换区域类型
    change_areaType(val) {
      this.hotelInlandCity = null;
      this.GangAoTaiId = "";
      this.hotelForeignCity = null;
      const form = this.formData;
      form.cityId = "";
      form.cityName = "";
    },

    // 删除已选项
    deleteTagList(key, value) {
      const form = this.formData;
      const list = form[key].split(",");
      list.splice(list.indexOf(value), 1);
      form[key] = list.join(",");
      this.search();
    },

    change_fold(key) {
      this[key] = !this[key];
    },

    select_option(key, value) {
      const form = this.formData;
      if (value) {
        const list = form[key] ? form[key].split(",") : [];
        if (!list.includes(value)) {
          list.push(value);
          form[key] = list.join(",");
        }
      } else {
        form[key] = "";
      }
      this.search();
    },
    // 升序：asc,降序：desc
    change_sort(newVal, index) {
      const odlVal = this.sortType;
      if (odlVal === newVal) {
        if (newVal == 1) return;
        const sortList = this.allocation.sortList;
        const oldType = sortList[index].type;
        sortList[index].type = oldType === "asc" ? "desc" : "asc";
      }

      this.sortType = newVal;

      this.search();
    },

    format_starClass(val) {
      if (["20"].includes(String(val))) {
        return "info-ico-a";
      } else if (["25", "30"].includes(String(val))) {
        return "info-ico-b";
      } else if (["35", "40"].includes(String(val))) {
        return "info-ico-d";
      } else if (["45", "50", "55"].includes(String(val))) {
        return "info-ico-f";
      }
    },

    to_detail(val) {
      const {
        currentPage,
        pageSize,
        checkInDate: checkInTime,
        checkOutDate: checkOutTime,
        cityId,
        cityName,
        hotelStar,
        hotelBrand,
        productType,
        salesSort,
      } = this.formData;
      setSession("HOTELINFO", val);
      setSession("HOTELFORMDATA", {
        currentPage,
        pageSize,
        checkInTime,
        checkOutTime,
        cityId,
        cityName,
        hotelStar,
        hotelBrand,
        productType,
        salesSort,
      });
      this.$router.push({
        name: "distributor-hotel-front-hotel-details",
      });
    },

    change_currentPage(currentPage) {
      this.formData.currentPage = currentPage;
      this.req_list();
    },
    change_pageSize(pageSize) {
      this.formData.pageSize = pageSize;
      this.search();
    },
  },
  beforeRouteEnter(to, from, next) {
    console.log(to.name, from.name);
    if (from.name === "distributor-hotel-front-hotel-details") {
      to.meta.refresh = false;
    } else {
      to.meta.refresh = true;
    }
    next();
  },
  activated() {
    this.init();
  },
  deactivated() {
    setSession("hotelSearchPageForm", this.formData);
  },
  mounted() {
    
  },
};
